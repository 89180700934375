import React,{ Component, useState, useEffect, useRef } from 'react'
import "./css/tag.css"
import { FavoriteHeart } from "./favorite"
import { Tag } from "./tag"
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const TagEdit = ({ label, onRemove }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', marginRight: '5px', paddingLeft: '8px', paddingRight: '3px', border: '1px solid #dadada', borderRadius: '5px' }}>
      <span style={{ marginRight: '10px' }}>{label}</span>
      <IconButton size="small" onClick={() => onRemove(label)} aria-label="delete">
        <CloseIcon fontSize="small"/>
      </IconButton>
    </div>
  );
};

export const TagEditList = ({track, onTagChange}) => {
  const [userTags, setUserTags] = useState("")
  const [newTag, setNewTag] = useState('');
  const [finalTagList, setFinalTagList] = useState([])
  const [isFavorite, setIsFavorite] = useState(false)

  // Initialize state with trackTags if it's an array, otherwise an empty array
  const [editTags, setEditTags] = useState([]);

  useEffect(() => {
      if (track.tag && track.tag.length > 0) {
        const tagList = track.tag.split(',')
        if (tagList.includes("heart")) {
            setIsFavorite(true)
        } else {
            setIsFavorite(false)
        }
          
        setFinalTagList(tagList)
        // Filter out the 'heart' tag and update the state
        const updatedTags = tagList.filter(tag => tag !== 'heart');
        setEditTags(updatedTags);
      }
  }, [track, isFavorite]);


  const handleFavoriteChange = (isFavorite) => {
      console.log("Favorite changed", isFavorite)
      var tagList = []
      if (isFavorite) {
          tagList = ["heart", ...finalTagList]
      } else {
          tagList = finalTagList.filter(tag => tag !== 'heart');
      }
      setIsFavorite(isFavorite)
      setFinalTagList(tagList)
      onTagChange([...tagList].join(','));
  }

  const handleKeyDown = (event) => {
   if (event.key === 'Enter' && newTag.trim() !== '') { // BIJUAlso ensure the newTag is not just empty or spaces
        setEditTags(prevTags => [...prevTags, newTag.trim()]); // Append newTag to the existing tags
        setNewTag(''); // Reset the input after adding
        const tagList = [...finalTagList, newTag.trim()];
        setFinalTagList(tagList);
        onTagChange([...tagList].join(','));
    }
  };

  const handleNewTagChange = (event) => {
      setNewTag(event.target.value)
  }

  const handleRemoveTag = (tag) => {
    const newTags = editTags.filter((selected) => selected !== tag);
    setEditTags(newTags);
    const tagList = finalTagList.filter((selected) => selected != tag);
    setFinalTagList(tagList)
    onTagChange(tagList.join(',')); // Pass updated comma-separated string to parent
  };

  return (
      <div className="tag-edit-list">
      <FavoriteHeart track={track} isFavorite={isFavorite} onFavoriteChange={handleFavoriteChange}/>
      {editTags.map((tag, index) => (
          <TagEdit key={tag} label={tag} onRemove={handleRemoveTag} />
      ))}
       <input
        type="text"
        placeholder="Add Tag"
        style={{border:"1px solid #eee", paddingLeft:"5px", borderRadius:"5px", lineHeight:2}}
        value={newTag}
        onChange={handleNewTagChange}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};
