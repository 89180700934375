import React,{ Component, useState, useEffect, useRef } from 'react'
import axios from 'axios';
import Header from "../header"
import "./css/music.css"
import { TagList } from "../common/tag_list"
import { TagEditList } from "../common/tag_edit_list"
import Button from '@mui/material/Button';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faTrash } from '@fortawesome/free-solid-svg-icons';

const server = axios.create({
  baseURL: 'https://walmates.com',
});

function objectToQueryString(obj) {
  const keyValuePairs = [];
  
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      // Encode the key and value to handle special characters
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(value);
      keyValuePairs.push(`${encodedKey}=${encodedValue}`);
    }
  }
  
  return keyValuePairs.join('&');
}

const MusicWidget = (props) => {
  const [tracks, setTracks] = useState([]);
  const [tags, setTags] = useState([]);
  const [play_track, setPlayLocalTrack] = useState(null);

  const initializeTracks = (songs) => {
    setTracks(songs)
  }

  const initializeTags = (tags) => {
      setTags(tags)
  }

  const addTrack = (track) => {
    console.log("Adding new Track: ", track.title)
    setTracks([track, ...tracks]);
  }
  const playLocalTrack = (track) => {
    setPlayLocalTrack(track)
  }

  const handleLocalSearch = (keyword, setLocalSearchResult) => {
    const lowercaseKeyword = keyword.toLowerCase();

    // Split the keyword into an array of substrings separated by spaces
    const keywordSubstrings = lowercaseKeyword.split(' ');

    // Filter the tracks based on whether any of the keyword substrings exist in title or artist
    const filteredTracks = tracks.filter((track) => {
      const lowercaseTitle = track.title.toLowerCase();
      const lowercaseArtist = track.artist.toLowerCase();

      // Check if any of the keyword substrings exist in title or artist
      return keywordSubstrings.every((substring) =>
        lowercaseTitle.includes(substring) || lowercaseArtist.includes(substring)
      );
    });

    // Update the state with the filtered tracks
    setLocalSearchResult(filteredTracks);
  }

  return (
    <>
      <div className="vault_table">
      <SearchWidget addTrack={addTrack} handleLocalSearch={handleLocalSearch} playLocalTrack={playLocalTrack}/>
      <div style={{paddingBottom:"2em", borderBottom:"1px solid #aaa"}}/>
      <TracksWidget initializeTracks={initializeTracks} initializeTags={initializeTags}  tracks={tracks} tags={tags} play_track={play_track}/>
      </div>
    </>
  );
}
export default MusicWidget;

const TracksWidget = (props) => {
  const [initialized, setInitialized] = useState(false)
  const {initializeTracks,initializeTags, tracks, tags, play_track } = props;
  const [keyword, setKeyword] = useState('');
  const [nowPlayingIndex, setNowPlayingIndex] = useState(-1);
  const [nowPlayingTitle, setNowPlayingTitle] = useState('');
  const [nowPlayingArtist, setNowPlayingArtist] = useState('');
  const [nowPlayingThumbnail, setNowPlayingThumbnail] = useState('')

  const [audioSrc, setAudioSrc] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const audioElementRef = useRef();

  useEffect(() => {
    if (play_track != null) {
      console.log("Play Track Changed:", play_track.title)
      // Find the index of the track with a matching ID or other identifier
      const index = tracks.findIndex((track) => track.video_id === play_track.video_id);

      if (index !== -1) {
        // Call your handlePlayTrack function with the index and play_track
        handlePlayTrack(index, play_track);
      }
    }
  }, [play_track, tracks]);

  useEffect(() => {
    console.log("Now Playing changed to:", nowPlayingIndex, " title:", nowPlayingTitle)
  }, [nowPlayingIndex]);

  const gotoPreviousTrack = () => {
    if (tracks.length == 0) {
      return;
    }
    console.log("PlayTrack: ", nowPlayingIndex, " length:", tracks.length);
    let nextPlaying = nowPlayingIndex - 1
    if (nowPlayingIndex == -1) {
        nextPlaying = 0
    }
    else {
      if (nextPlaying < 0) {
        nextPlaying = tracks.length - 1
      }
    }
    handlePlayTrack(nextPlaying, tracks[nextPlaying]);
  }

  const gotoNextTrack = () => {
    if (tracks.length == 0) {
      return;
    }
    console.log("PlayTrack: ", nowPlayingIndex, " length:", tracks.length);
    let nextPlaying = nowPlayingIndex + 1
    nextPlaying = nextPlaying % tracks.length;
    handlePlayTrack(nextPlaying, tracks[nextPlaying]);
  }

  const shuffleTracks = () => {
    if (tracks.length == 0) {
      return;
    }
    let shuffle = [...tracks]
      // Fisher-Yates shuffle algorithm
    for (let i = shuffle.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffle[i], shuffle[j]] = [shuffle[j], shuffle[i]];
    }
    setNowPlayingIndex(-1)
    initializeTracks(shuffle)
  }

  const updateAudioMedia = (index, url, param) => {
    // Pause the audio before setting the new source
    audioElementRef.current.load();     

    setAudioSrc(url); // Set the new audio source

    // Play the new track
    audioElementRef.current.play();

    setNowPlayingTitle(param.title)
    setNowPlayingIndex(index); // Update the current playing track index
    setNowPlayingArtist(param.artist)
    setNowPlayingThumbnail(param.thumbnail_default)
  }

  const handleDeleteTrack = async(index, param) => {
    let token = localStorage.getItem('walmates-token');
    let user_id = localStorage.getItem('walmates-user_id');
    server.post(`/api/music/deleteTrack?user_id=${user_id}&video_id=${param.video_id}`, null, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(result => {
      console.log('Track Deleted: ', result);
      const updatedTracklist = tracks.filter((track) => track.video_id !== param.video_id);
      initializeTracks(updatedTracklist);
      console.log("Delete Track: ", param.title)
    })
    .catch(err => {
      console.error('Error in deleting track:', param.title, 'error:', err);
    });
  }

  const handlePlayTrack = async (index, param) => {
    if (nowPlayingTitle === param.nowPlayingTitle) {
      console.log("It's the same song..");
      return;
    }

    const token = await localStorage.getItem('walmates-token');
    const user_id = await localStorage.getItem('walmates-user_id');
    //const localFilePath = `${RNFS.DocumentDirectoryPath}/${param.video_id}.mp3`;

    // Check if the track exists locally
    const isTrackDownloaded = false; //await RNFS.exists(localFilePath);

    if (isTrackDownloaded) {
      // Track is already downloaded, play it from the local file system
      console.log("Track title:", param.title, " playing from cache");
      // updateAudioMedia(index, `file://${localFilePath}`, param);
    } else {
      server
        .get(`/api/music/playTrack?user_id=${user_id}&name=${param.name}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'blob',
        })
        .then(async (response) => {
          console.log("PlayTrack: ", index, " title:", param.title, " total:", tracks.length);
          const blob = new Blob([response.data], { type: 'audio/mpeg' });
          const url = URL.createObjectURL(blob);
          updateAudioMedia(index, url, param);
        })
        .catch((err) => {
          console.error("Error in playing track:", param.title, ' error:', err);
        });
    }
  };

  const onTagSelectionChange = async(selectedTags) => {
    console.log("Selected Tags:", selectedTags)
    let token = localStorage.getItem('walmates-token');
    let user_id = localStorage.getItem('walmates-user_id');

    const queryString = selectedTags.map(tag => `tags=${encodeURIComponent(tag)}`).join('&');
    const url = `/api/music/getTracks?user_id=${encodeURIComponent(user_id)}&${queryString}`;
    server.get(url, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(result => {
      console.log("Selected User Tracks", result);
      initializeTracks(result.data);
      setInitialized(true)
    })
    .catch(err => {
      console.error("Error to search ", keyword, " error:", err);
    });
  }

  useEffect(() => {
    // Add the ended event listener to the audio element
    if (initialized == false) {
      return;
    }
    const handleEnded = () => {
      console.log("PlayTrack: ", nowPlayingIndex, " length:", tracks.length);
      let nextPlaying = (nowPlayingIndex + 1) % tracks.length;
      handlePlayTrack(nextPlaying, tracks[nextPlaying]);
    };

    audioElementRef.current.addEventListener('ended', handleEnded);

    // Clean up the event listener when the component unmounts
    return () => {
      if (audioElementRef.current) {
          audioElementRef.current.removeEventListener('ended', handleEnded);
      }
    };
  }, [initialized, nowPlayingIndex]);
    

  useEffect(() => {
    let token = localStorage.getItem('walmates-token');
    let user_id = localStorage.getItem('walmates-user_id');

    server.get(`/api/music/getTags?user_id=${user_id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(result => {
      console.log("User Tags", result.data)
      initializeTags(result.data)
    })
    .catch(err => {
      console.error("Error toget tracks:  error:", err);
    }); 

    server.get(`/api/music/getTracks?user_id=${user_id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(result => {
      // console.log("User Tracks", result);
      initializeTracks(result.data);
      setInitialized(true)
    })
    .catch(err => {
      console.error("Error to search ", keyword, " error:", err);
    });

  }, []);

  return (
    <>
      <div style={{ background: "#f3f3f3", padding: "13px" }}>
        <div>
          {nowPlayingIndex >= 0 ? 
            <li className="track-item" style={{background:"#333", color:"white"}}>
              <div className="track-image">
                <img src={nowPlayingThumbnail}/>
              </div>
              <div className="track-details">
                <h3>{nowPlayingTitle}</h3>
                <p>{nowPlayingArtist}</p>
              </div>
            </li>
         : null}  
          <div className="audio-controls">
            <Button style={{color:'#333'}} onClick={shuffleTracks}>
             <ShuffleIcon/>
            </Button>
            <Button style={{color:'#333'}} onClick={gotoPreviousTrack}>
             <SkipPreviousIcon/>
            </Button>
            <Button style={{color:'#333'}} onClick={gotoNextTrack}>
             <SkipNextIcon/>
            </Button>
            <audio ref={audioElementRef} controls>
              <source src={audioSrc} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </div>
        </div>
        { tags.length > 0 ? (<TagList tags={tags} onSelectionChange={onTagSelectionChange} mode={"checkbox"} />) : null }
        <TrackList tracks={tracks} onTrackClick={handlePlayTrack} onTrackDelete={handleDeleteTrack} />
      </div>
    </>
  );
};

const SearchWidget = (props) => {
  const {addTrack, playLocalTrack} = props
  const [tracks, setSearchResult] = useState([]);
  const [local_tracks, setLocalSearchResult, setPlayLocalTrack] = useState([]);
  const [keyword, setKeyword] = useState('');

  const handleKeywordEdit = (nkw) => {
    setKeyword(nkw);
    if (nkw.length > 2) {
      props.handleLocalSearch(nkw, setLocalSearchResult);
    }
    else {
      setLocalSearchResult([])
    }
  };

  const handlePlayLocalTrack = (index, track) => {
    playLocalTrack(track)
    setLocalSearchResult([])
    setKeyword('')
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch(keyword);
    } 
  };


  const handleSearch = (search_string) => {
    let token = localStorage.getItem('walmates-token');
    server.get(`/api/music/search?title=${search_string}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(result => {
        // console.log("Search Results", result);
        // Update search_results state or perform other actions with the result data
        setSearchResult(result.data);
      })
      .catch(err => {
        console.error("Error to search ", search_string, " error:", err);
      });
  };

  const handleAddTrack = (index, param) => {
    let token = localStorage.getItem('walmates-token');
    let user_id = localStorage.getItem('walmates-user_id');
    
    // Check if token and user_id exist
    if (!token || !user_id) {
      console.error('Token or user_id not found in localStorage');
      return;
    }
    console.log("Adding Track: ", param.title)
    setKeyword('')
    setSearchResult([])

    // Set user_id in the param object
    //let msg = objectToQueryString(param)
    server.post(`/api/music/addTrack?user_id=${user_id}`, param, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(result => {
      console.log('Track Added: ', result);
      addTrack(result.data)
    })
    .catch(err => {
      console.error('Error in downloading:', param.title, 'error:', err);
    });
  }

  const handleDeleteTrack = async(index, param) => {
    let token = localStorage.getItem('walmates-token');
    let user_id = localStorage.getItem('walmates-user_id');
    server.post(`/api/music/deleteTrack?user_id=${user_id}&video_id=${param.video_id}`, null, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(result => {
      console.log('Track Deleted: ', result);
      const updatedTracklist = tracks.filter((track) => track.video_id !== param.video_id);
      setLocalSearchResult(updatedTracklist);
      console.log("Delete Track: ", param.title)
    })
    .catch(err => {
      console.error('Error in deleting track:', param.title, 'error:', err);
    });
  }

  return (
    <>
      <div style={{ padding: "2px", borderRadius: "2px"}}>
        <input
          type="text"
          className="falcon_text"
          value={keyword}
          style={{ fontSize: "20px", color: "white", padding: "10px", background:"#333", borderRadius:"5px" }}
          placeholder="Search Songs, Artist.."
          onChange={(e) => { handleKeywordEdit(e.target.value) }}
          onKeyDown={handleKeyDown} />
      </div>
      {local_tracks.length > 0 && (
        <div style={{background:"#aaa", padding:"13px"}}>
            <TrackList tracks={local_tracks} onTrackClick={handlePlayLocalTrack} onTrackDelete={handleDeleteTrack}/>
         </div>
      )}
      {tracks.length > 0 && (
        <div style={{background:"#aaa", padding:"13px"}}>
            <TrackList tracks={tracks} onTrackClick={handleAddTrack}/>
         </div>
      )}
    </>
  );
};

const TrackList = ({ tracks, onTrackClick, onTrackDelete }) => {

  const onTrackUpdate = (index, track, field, value) => {
    console.log("Updating Track:", track.title, " field:", field, " value:", value)
    let token = localStorage.getItem('walmates-token');
    let user_id = localStorage.getItem('walmates-user_id');
    server.post(`/api/music/updateTrack?user_id=${user_id}&video_id=${track.video_id}&field=${field}&value=${value}`, null, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(result => {
      console.log('Track[', track.title, '] Field:', field, " => ", value);
    })
    .catch(err => {
      console.error('Error in updating track:', track.title, 'error:', err);
    });
  }

  return (
    <ul className="track-list">
      {tracks.map((track, index) => (
        <li key={index} className="track-item">
          <div className="track-image" onClick={() => onTrackClick(index, track)}>
            <img src={track.thumbnail_default} alt={`Track ${index}`} />
          </div>
          <div className="track-details">
            <h3>{track.title}</h3>
            <p>Artist: {track.artist}</p>
            <TagEditList track={track} onTagChange={(newTag) => onTrackUpdate(index, track, "tag", newTag)} />
          </div>
          {onTrackDelete != null ?
          <div className="delete-icon" onClick={() => onTrackDelete(index, track)} style={{color:"red", marginRight:"5px", cursor:"pointer"}}>
            <FontAwesomeIcon icon={faTrash} />
          </div>
          : null}
        </li>
      ))}
    </ul>
  );

  // Define a function to handle delete click if needed
  const onDeleteClick = (index) => {
    // Handle the delete action here, e.g., by passing the index to a delete function.
  };
};

