import React, { useState, useEffect } from 'react';
import Header from '../header';
import { FaTrash } from "react-icons/fa"

import '../css/todo.css';
import '../css/todo_item.css';

const Todo = () => {
  function getInitialTodos() {
    const temp = localStorage.getItem('walmates.todos.tasks');
    let loadedTodos = []
    if (temp != null && temp.length != 0) {
        loadedTodos = JSON.parse(temp);
    }
    console.log("Loaded Tasks:", loadedTodos)
    return loadedTodos;
  }

  const [todos, setTodos] = useState(getInitialTodos());

  useEffect(() => {
    const temp = JSON.stringify(todos);
    localStorage.setItem('walmates.todos.tasks', temp);
  }, [todos]);

  const handleChange = (id) => {
    setTodos((prevState) =>
      prevState.map((todo) => {
        if (todo.id === id) {
          return {
            ...todo,
            completed: !todo.completed,
          };
        }
        return todo;
      })
    );
  };

  const addTodo = (todo) => {
    console.log("Adding todo(parent=", todo.parent_key, " key=", todo.key, " title:", todo.title);
    if (todo.key == null || todo.key.length == 0 || todo.parent_key == null || todo.parent_key.length == 0) {
        console.log("Invalid Task:", todo);
        return;
    }
    setTodos([todo, ...todos]);
  };

  const updateTodo = (utodo) => {
      console.log("Updating Task:", utodo)
      if (utodo == null || utodo.key == null || utodo.key.length === 0) {
        console.log("Invalid todo; ignoring update");
        return;
      }

      setTodos(prevTodos => prevTodos.map(todo => {
        if (todo.key === utodo.key) {
          return { ...todo, ...utodo }; // Update the specific todo with the updatedTodo
        }
        return todo;
      }));
  };


  const deleteTodo = (key) => {
    console.log("Deleting Todo:", key)
    if (key == null || key.length == 0) {
        console.log("Invalid key, ignore delete")
    }
    setTodos((prevTodos) => prevTodos.filter((todo) => !todo.key.startsWith(key)));
  };

  const updateTitle = (key, updateTitle) => {
    setTodos((prevTodos) =>
      prevTodos.map((todo) => {
        if (todo.key === key) {
          return {
            ...todo,
            title: updateTitle,
          };
        }
        return todo;
      })
    );
  };

  const findTodos = (parent_key) => {
      const result = todos.filter((todo) => todo.parent_key === parent_key);
      return result || []
  };

  const getKey = () => {
      const key = "root-" + (todos.length + 1)
      return key
  }

  return (
    <div className="vault_table">
      <Header />
      <div style={{border:"1px solid #eee", padding:"20px"}}>
      <div className="input-container">
        <InputTodo addTask={addTodo} parent_key="root" task_key={getKey()} placeHolder="Add Todo" />
      </div>
        {[
          ...findTodos("root").filter(task => !task.completed), // Uncompleted tasks go first
          ...findTodos("root").filter(task => task.completed)   // Completed tasks come after
        ].map(task => (
        <TaskList
          key={task.key}
          all_tasks = {todos}
          addTask = {addTodo}
          deleteTask = {deleteTodo}
          updateTask = {updateTodo}
          findTasks = {findTodos}
          task={task}
         />
      ))}
      </div>
    </div>
  );
};

class TaskList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            task: props.task,
            show_tasks: false,
            tasks: props.findTasks(props.task.key),
        };
    }

  toggleShowTasks = () => {
    this.setState({
        show_tasks: !this.state.show_tasks,
    })
  };

  getKey = () => {
      const task_key = this.state.task.key + "-" + (this.state.tasks.length + 1);
      return task_key;
  }

  addTask = (task) => {
    if (task.key == null || task.key.length == 0 || task.parent_key == null || task.parent_key.length == 0) {
        console.log("Invalid Child Task:", task);
        return;
    }
    this.setState((prevState) => ({ tasks: [task, ...prevState.tasks] }))
    this.props.addTask(task);
  }

    deleteTask = (key) => {
        console.log("Deleting Task:", key);

        if (key == null || key.length === 0) {
            console.log("Invalid delete task: ", key);
            return;
        }

        // Assuming deleteTask is a function passed as a prop from the parent component
        this.props.deleteTask(key); // Call the deleteTask function from props

        this.setState(prevState => ({
            tasks: prevState.tasks.filter(task => task.key !== key)
        }));
    };

  toggleTaskCompleted = () => {
    this.setState( prevState => ({
        task: {
            ...prevState.task, 
           completed: !prevState.task.completed
        }
    }), ()=> {
        this.props.updateTask(this.state.task)
    });
   }

  getColorFromKey = (key) => {
    const hyphensCount = key.split('-').length - 1;
    const grayscaleValue = 30 + (hyphensCount * 30); // Adjust 30 as per your preference
    // Create a CSS color value based on the grayscale
    const color = `rgb(${grayscaleValue}, ${grayscaleValue}, ${grayscaleValue})`;
    return color;
  };

   getTitleStyle = () => {
       let st = {}
        if (this.state.task.completed) {
            st.fontStyle = "italic"
            st.color = "#595959"
            st.opacity = 0.4
            st.textDecoration = "line-through"
        }
       if (this.state.tasks && this.state.tasks.length > 0) {
           st.fontWeight = "bold"
           st.fontSize = "18px"
           st.color = this.getColorFromKey(this.state.task.key)
           st.textDecoration = 'underline'
       }
       st.cursor = "pointer"
       return st;
   }

    render() {
        
        const completedStyle = {
            fontStyle: "italic",
            color: "#595959",
            opacity: 0.4,
            textDecoration: "line-through",
        }

        return (
        <li className="item"> 
          <div>
            <input 
              type="checkbox" 
              className="checkbox"
              checked={this.state.task.completed}
              onChange={this.toggleTaskCompleted}/> 
            <button 
                onClick={() => this.deleteTask(this.state.task.key)}>
                <FaTrash style={{ color: "grey", fontSize: "16px" }} />
            </button>
            <span style={this.getTitleStyle()} onClick={this.toggleShowTasks}>
            { this.state.task.title} 
            </span>

            {this.state.show_tasks && (
                <div className="subtask-outer-container">
                <div className="subtask-inner-container">
                <InputTodo addTask={this.addTask} parent_key={this.state.task.key} task_key={this.getKey()} placeHolder="Add Task" />
            
                {[
                  ...this.state.tasks.filter(task => !task.completed), // Uncompleted tasks go first
                  ...this.state.tasks.filter(task => task.completed),  // Completed tasks come after
                ].map(task => (
                <TaskList
                  key={task.key}
                  all_tasks = {this.props.all_tasks}
                  addTask = {this.props.addTask}
                  updateTask = {this.props.updateTask}
                  deleteTask = {this.deleteTask} // parent delete task
                  findTasks = {this.props.findTasks}
                  task={task}
                 />
                ))}

                </div>
                </div>
            )}
        </div>
        </li>
        )
    }
}

const InputTodo = ({ addTask, parent_key, task_key, placeHolder }) => {
  const [title, setTitle] = useState('');

  const handleInputChange = (event) => {
    setTitle(event.target.value);
  };

  const handleAddTask = () => {
    if (title.trim() !== '') {
      const task = {}
      task.parent_key = parent_key
      task.key = task_key
      task.title = title
      task.completed = false;
      addTask(task);
      setTitle('');
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleAddTask();
    }
  };

  return (
    <div className="input-container">
      <input
        type="text"
        value={title}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        placeholder={placeHolder}
        className="todo-input"
      />
    </div>
  );
};


export default Todo;
