import React,{ Component, useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import {Document, Page} from 'react-pdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons'

import "./css/vault.css"
import { FaPlusCircle } from "react-icons/fa"
import { FaFile, FaTrash } from 'react-icons/fa';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { useNavigate } from "react-router-dom";
import axios from 'axios'

import Header from "../header"
import { TagList } from "../common/tag_list"

const Vault = () => {

    const [documents, setDocuments] = useState(null)
    const [tagData, setTagData] = useState([])
    const [tags, setTags] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        let token = localStorage.getItem('walmates-token')
        axios.get("https://walmates.com/api/vault/get_tags", {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(result => {
            setTagData(result.data)
            const tagNames = result.data.map(tdata => tdata.tag);
            setTags(tagNames)
        })
        .catch(err => {
          console.error(err)
          navigate("/login", {replace:true});
        });
    }, [])

    const handleSearchResults = (data) => {
        console.log("SetDocuments:", data)
        setDocuments(data)
    }

    const handleDocumentAdd = (title) => {
        let token = localStorage.getItem('walmates-token')
        let user_id = localStorage.getItem('walmates-user_id')
        let message = "user_id="+ user_id
        message += "&title=" + title
        console.log("Add Doc:", message)
        axios.get(`https://walmates.com/api/vault/add?${message}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
        .then(result => {
            alert(title + ": added")
        })
        .catch(err => {
            alert(title + ": Failed to add: " + err)
          console.error(err)
        });
    }

    return (
        <div className="vault_table">
          <div style={{marginBottom:"1em", border: "1px solid #eee", borderRadius:"10px"}}>
          <div style={{padding:"1em"}}>
          </div>
          <VaultTags tags={tags} handleSearchResults={handleSearchResults}/>
          </div>
          <div style={{background:"#f7f7f7"}}>
          <div style={{background:"white", borderRadius:"10px", border: "1px solid #f0f0f0"}}>
          <Search onSearchResults={handleSearchResults} onAddDocument={handleDocumentAdd}/>
          </div>
          </div>
          <Documents documents={documents}/>
        </div>
      )
}

export default Vault

const EditableDiv = (props) => {
  const divRef = useRef(null);

  const handleBlur = () => {
    const text = divRef.current.textContent;
    if (props.handleBlur != null) {
        props.handleBlur(props.id, props.field, text)
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();

    const text = event.clipboardData.getData("text/plain");
    const selection = window.getSelection();

    if (!selection.rangeCount) return false;

    selection.deleteFromDocument();

    const range = selection.getRangeAt(0);
    const node = range.createContextualFragment(text);
    range.insertNode(node);
  };

  const handleKeyDown = (event) => {
    if(event.key === "Enter" && !event.shiftKey) {
      // Enter key is pressed
      event.preventDefault(); // prevent default behavior
      // handle enter key press here
      if (props.handleEnter != null) {
        let text = divRef.current.innerHTML;
        text = text.replace(/\n/g, '<br/>')
        console.dir("inner HTML for " + props.field + "=" + text);
        props.handleEnter(props.id, props.field, text)
      }
    }
  };

  let decodedString = props.value
    try {
      if(decodedString != null) {
        decodedString = decodeURIComponent(decodedString);
      }
    } catch (error) {
      console.log("Failed to decode String: ", props.value)
    }

  return (
    <div
      ref={divRef}
      contentEditable={true}
      style={{
          display:'flex',
          padding:'12px',
          justifyContent:'left',
          fontFamily:'Helvetica', 
          whiteSpace: "pre",
          fontSize:props.size, 
          color:props.color
      }}
      dangerouslySetInnerHTML={{ __html: decodedString}} 
      onPaste={handlePaste}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
    >
    </div>
  );
}


const UploadDiv = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0])
    console.log("Selected file:", event.target.files[0], " vault_id=", props.id)
    props.onFileUpload(props.id, event.target.files[0])
  }

  const input_id = `file-input-${props.id}`
  function handleClick() {
      document.getElementById(input_id).click();
  }

  return (
    <div style={{ width: "100%", paddingBottom:"5px" }}>
      <div style={{ display: 'flex', justifyContent: "space-between", alignItems:"right" }}>
      <div style={{display:"flex", alignItems:"center", marginLeft:"5px"}}>
        <input
          id={input_id}
          type="file"
          onChange={handleFileSelect}
          style={{ display: "none" }}
        />
        <FontAwesomeIcon icon={faPaperclip} onClick={handleClick} style={{cursor:"pointer"}}/>
        {selectedFile && <div style={{display:"flex"}}>{selectedFile.name} </div>}
      </div>
      </div>
    </div>
  )
}

const FileComponent = (props) => {
  const [fileUrl, setFileUrl]=useState(null)
  const [pdfUrl, setPdfUrl]=useState(null)
  const fileExtension = props.finfo.extn
  const isImage = fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png' || fileExtension === 'gif';
  const isPdf = fileExtension === 'pdf';

  const deleteAttachment = (event) => {
      console.log("Delete attachment")
  }

  const downloadFileUrl = () => {
    console.log("FileURl :", fileUrl)
    if (fileUrl == null) {
        let token = localStorage.getItem('walmates-token')
        let user_id = localStorage.getItem('walmates-user_id')
        axios.get(`https://walmates.com/api/vault/downloadfile?user_id=${user_id}&file=${props.finfo.file_name}`, {
            headers: {
                'Authorization': `Bearer ${token}`, 
            },
            responseType:'blob',
        }).then(response => {
            const url = URL.createObjectURL(response.data)
            setFileUrl(url)
            window.open(url)
        })
    }
    else {
        window.open(fileUrl)
    }
  }

  const handleRemove = () => {
    const confirmed = window.confirm(`Are you sure you want to remove ${props.finfo.file_name}?`);
    if (confirmed) {
        let token = localStorage.getItem('walmates-token')
        let user_id = localStorage.getItem('walmates-user_id')
        axios.get(`https://walmates.com/api/vault/removefile?user_id=${user_id}&vault_id=${props.finfo.vault_id}&file_id=${props.finfo.id}`, {
            headers: {
                'Authorization': `Bearer ${token}`, 
            },
        }).then(response => {
            console.log("FILE removed:", response)
            props.onFileRemove(props.finfo.vault_id, props.finfo.id)
        })
    } 
  }

  return (
    <div className="file-component">
      <FaFile style={{ margin: '0 8px', fontSize: '16px' }} />
      <div className="file-component-text" onClick={downloadFileUrl}>
        {props.finfo.name}
      </div>
      <button style={{ border: 'none', backgroundColor: 'transparent', margin: '0 8px', color: 'red', cursor: 'pointer' }} onClick={handleRemove}>
        <FaTrash style={{ fontSize: '16px' }} />
      </button>
    </div>
  );
}

const VaultTags = ({tags, handleSearchResults}) => {

    const handleTagClick = (tags) => {
        if (tags.length == 0) {
            handleSearchResults([])
            return;
        }
        let token = localStorage.getItem('walmates-token')
        let user_id = localStorage.getItem('walmates-user_id')
        let keyword = tags[0] + " --tags"
        axios.get(`https://walmates.com/api/vault/get?user_id=${user_id}&keyword=${keyword}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(result => {
            let data = new Map()
            result.data.map(d => {
                if(!data.has(d.vault_id)) {
                    d.files = []
                    data.set(d.vault_id, d)
                }
                if(d.file_id != null && d.file_name != null) {
                    let ed = data.get(d.vault_id)
                    let finfo = {}
                    finfo.id = d.file_id
                    finfo.vault_id = d.vault_id
                    finfo.user_id = d.file_user_id 
                    finfo.file_name = d.file_name
                    finfo.extn= d.file_name.substring(d.file_name.lastIndexOf(".") + 1)
                    finfo.name = d.file_name.substring(0, d.file_name.lastIndexOf("."))
                    ed.files.push(finfo)
                }
            })
            handleSearchResults(data)
        })
    }

    return (
        <>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
        {
            <TagList tags={tags} onSelectionChange={handleTagClick} mode={"radio"}/>
        }
        </div>
        </>
    )
}

const Search = ({onSearchResults, onAddDocument}) => {

    const [keyword, setKeyword] = useState("")
    const [searchResults, setSearchResuls] = useState([])

    const handleKeywordEdit= (nkw) => {
        setKeyword(nkw)
    }

    useEffect(() => {
        if(keyword.length < 3) {
            return
        }
        let token = localStorage.getItem('walmates-token')
        let user_id = localStorage.getItem('walmates-user_id')
        axios.get(`https://walmates.com/api/vault/get?user_id=${user_id}&keyword=${keyword}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(result => {
            let data = new Map()
            result.data.map(d => {
                if(!data.has(d.vault_id)) {
                    d.files = []
                    data.set(d.vault_id, d)
                }
                if(d.file_id != null && d.file_name != null) {
                    let ed = data.get(d.vault_id)
                    let finfo = {}
                    finfo.id = d.file_id
                    finfo.vault_id = d.vault_id
                    finfo.user_id = d.file_user_id 
                    finfo.file_name = d.file_name
                    finfo.extn= d.file_name.substring(d.file_name.lastIndexOf(".") + 1)
                    finfo.name = d.file_name.substring(0, d.file_name.lastIndexOf("."))
                    ed.files.push(finfo)
                }
            })
            setSearchResuls(result.data)
            onSearchResults(data)
        })
    }, [keyword])

    return (
        <>
        <div style={{padding:"2px", border:"1px solid #f3f3f3"}}>
        <input
            type="text"
            className="falcon_text"
            value={keyword}
            style={{fontSize:"20px", color:"blue", padding:"10px"}}
            placeholder="Search:"
            onChange={(e) => {
                handleKeywordEdit(e.target.value)
            }}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    if(searchResults.length == 0) {
                        console.log("Adding new document", e.target.value)
                        onAddDocument(e.target.value)
                    }
                } 
            }}
            onBlur={(e) => {}}/>
        </div>
        </>
    )
}

const Documents = (props) => {
    let row = 0
    const onFileUpload = (vault_id, selectedFile) => {
        const formData = new FormData()
        formData.append('file', selectedFile)
        let token = localStorage.getItem('walmates-token')
        let user_id = localStorage.getItem('walmates-user_id')
        axios.post(`https://walmates.com/api/vault/addfile?user_id=${user_id}&vault_id=${vault_id}&file_name=${selectedFile.name}`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`, 
                'Content-Type': 'multipart/form-data',
            },
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
            onUploadProgress: progressEvent => {
                console.log(
          '     Upload progress: ' +
                Math.round((progressEvent.loaded / progressEvent.total) * 100) + '%')
            }
        })
        .then(response => {
            console.log(response.data)
            // insert into the document. 
        })
        .catch(error => {
            console.log(error)
        });
    }

    const onFileRemove = (vault_id, file_id) => {
        console.log("On file Remove: vault", vault_id, " file:", file_id)
    }

    function getRowColor(index) {
        return index % 2 === 0 ? "#fff" : "#f7f7f7";
    }

    function handleUpdate(id, field, text) {
        let token = localStorage.getItem('walmates-token')
        let user_id = localStorage.getItem('walmates-user_id')
        let message = "user_id="+ user_id + "&vault_id=" + id + "&" + field + "=" + text
        console.log("Edit:", message)
        axios.get(`https://walmates.com/api/vault/update?${message}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
        .then(result => {
            alert("vault id:[" + id + "]" +  field + " changed to " + text)
        })
        .catch(err => {
            alert("Failed to update vault id:[" + id + "]" +  field + " to value " + text)
          console.error(err)
        });
    }

    return (
        <>
        <table style={{width:"100%", marginTop:"3px"}}>
        {
            props.documents && Array.from(props.documents).map(([key, d])  => 
                <tr style={{borderBottom:"1px solid #dfdfdf"}}> 
                <td style={{borderRight:"1px solid #efefef"}}>
                <EditableDiv id={d.vault_id} field="title" value={d.title} size="1.25em" color="black" handleEnter={handleUpdate}/>
                </td>
                <td style={{borderRight:"1px solid #efefef"}}>
                    <EditableDiv id={d.vault_id} field="data" value={d.data} size="14px" color="#333" handleEnter={handleUpdate}/>
                <div style={{borderTop:"1px solid #f7f7f7"}}>
                <div style={{marginTop:"5px", background:"white"}}>
                <EditableDiv id={d.vault_id} field="tags" value={d.tags} size="16px" color="blue" handleEnter={handleUpdate}/>
                </div>
                </div>
                </td>
                <td style={{textAlign: 'left', verticalAlign: 'top'}}>
                <UploadDiv id={d.vault_id} onFileUpload={onFileUpload}/>
                {
                    d.files && d.files.map( finfo => {
                        return <FileComponent finfo={finfo} onFileRemove={onFileRemove}/>
                    })
                }
                </td>
                </tr>
            )
        }
        </table>
        </>
    )
}
