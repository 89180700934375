// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
    font-family: "Segoe UI", Arial, sans-serif;
    line-height: 1.4;
    color: \$444;
    background:#fff;
    height: 100vh;
}

ul {
    list-style-type: none;
}

ul > li {
    display: inline-block;
}

.wall {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
  margin: 0 auto;
}

.apps_container {
  display:inline;
}

.app_cover {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background:#fff;
  border: 1px solid #ccc;
  border-radius:5px;
  margin:1em;
  justify-content:center;
}

.app_cover:hover {
  background-color: #ccc;
  cursor: pointer;
}

.app_logo {
  display:flex;
  width:80px;
  border-radius:50%;
  padding: 10px;
}
.app_title {
  font-weight: 500;
  color:#666;
}
`, "",{"version":3,"sources":["webpack://./src/css/walmates.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,SAAS;EACT,UAAU;AACZ;;AAEA;IACI,0CAA0C;IAC1C,gBAAgB;IAChB,WAAW;IACX,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,eAAe;EACf,sBAAsB;EACtB,iBAAiB;EACjB,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,UAAU;EACV,iBAAiB;EACjB,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,UAAU;AACZ","sourcesContent":["* {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n}\n\nbody {\n    font-family: \"Segoe UI\", Arial, sans-serif;\n    line-height: 1.4;\n    color: $444;\n    background:#fff;\n    height: 100vh;\n}\n\nul {\n    list-style-type: none;\n}\n\nul > li {\n    display: inline-block;\n}\n\n.wall {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  max-width: 600px;\n  margin: 0 auto;\n}\n\n.apps_container {\n  display:inline;\n}\n\n.app_cover {\n  display: flex;\n  flex-direction: column;\n  padding: 10px;\n  background:#fff;\n  border: 1px solid #ccc;\n  border-radius:5px;\n  margin:1em;\n  justify-content:center;\n}\n\n.app_cover:hover {\n  background-color: #ccc;\n  cursor: pointer;\n}\n\n.app_logo {\n  display:flex;\n  width:80px;\n  border-radius:50%;\n  padding: 10px;\n}\n.app_title {\n  font-weight: 500;\n  color:#666;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
