// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  background:#fff;
  padding:10px;
  border-radius:5px;
  margin-bottom:5px;
}
.tag-edit-list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  border-radius:5px;
  font-size: 14px; /* Style for artist and description */
}

.tag-container {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding:5px;
  padding-left:10px;
  padding-right:10px;
  border: 1px solid #ccc;
  border-radius:5px;
}

.tag input[type="checkbox"] {
  margin-right: 10px;
}
.tag input[type="radio"] {
  margin-right: 10px;
}

.tag-spacer {
  margin: 0 5px;
}
`, "",{"version":3,"sources":["webpack://./src/common/css/tag.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,QAAQ;EACR,eAAe;EACf,YAAY;EACZ,iBAAiB;EACjB,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,eAAe;EACf,QAAQ;EACR,iBAAiB;EACjB,eAAe,EAAE,qCAAqC;AACxD;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,WAAW;EACX,iBAAiB;EACjB,kBAAkB;EAClB,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".tag-list {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 5px;\n  background:#fff;\n  padding:10px;\n  border-radius:5px;\n  margin-bottom:5px;\n}\n.tag-edit-list {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 5px;\n  border-radius:5px;\n  font-size: 14px; /* Style for artist and description */\n}\n\n.tag-container {\n  display: flex;\n  align-items: center;\n  white-space: nowrap;\n  padding:5px;\n  padding-left:10px;\n  padding-right:10px;\n  border: 1px solid #ccc;\n  border-radius:5px;\n}\n\n.tag input[type=\"checkbox\"] {\n  margin-right: 10px;\n}\n.tag input[type=\"radio\"] {\n  margin-right: 10px;\n}\n\n.tag-spacer {\n  margin: 0 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
