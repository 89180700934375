
import React,{ Component, useState, useEffect } from 'react';
import {Link} from "react-router-dom"
import Header from "./header"
import axios from 'axios'
import Login from "./login"
import { useNavigate } from "react-router-dom";

const Walmates = () => {

    const [apps, setApps] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        let token = localStorage.getItem('walmates-token')
        axios.get(`https://walmates.com/api/apps`,{
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            console.log("Apps Data:", response.data)
            setApps(response.data);
        })
        .catch(error => {
            localStorage.removeItem('walmates-token')
            console.log("Error received:", error);
            navigate("/login", {replace:true, hidePath:true})
        });
    }, []);
 
    return (
        <>
        <div className="wall">
        <Header />
        <div className="apps_container">
        <ul>
        { apps.map(app => (
            <li key={app.id}>
            <App key={app.id} app={app} />
            </li>
        ))}
        </ul>
        </div>
        </div>
        </>
    )
}

const App = (props) => {
    return (
        <div className="app_cover">
        <Link to={"/" + props.app.name.toLowerCase()}>
        <img className="app_logo" src={props.app.icon}/>
        <div className="app_title">{props.app.name}</div>
        </Link>
        </div>
    )
}

export default Walmates

