import React,{ Component, useState, useEffect, useRef } from 'react'
import "./css/tag.css"
import { FavoriteHeart } from "./favorite"
import { Tag } from "./tag"

export const TagList = ({ tags, onSelectionChange, mode}) => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagMode, setTagMode] = useState(mode)

  const handleTagSelect = (tag) => {
    var newSelectedTags = selectedTags.includes(tag)
      ? selectedTags.filter((selected) => selected !== tag)
      : [...selectedTags, tag];
    if (tagMode == "radio") {
        if (newSelectedTags.includes(tag)) {
            newSelectedTags = [tag]
        }
    }
    setSelectedTags(newSelectedTags);
    onSelectionChange(newSelectedTags)
  };

  const handleFavoriteChange = (isSelected) => {
      var tags = []
      if (isSelected) {
          tags = ["heart", ...selectedTags]
      }
      else {
          tags = selectedTags.filter((tag) => tag != "heart")
      }
      onSelectionChange(tags)
  }

  const renderedTags = tags.filter(tag => tag.trim() !== "").map((tag) => (
    <Tag
      key={tag}
      label={tag}
      mode = {tagMode}
      onSelect={handleTagSelect}
      selected={selectedTags.includes(tag)}
    />
  ));

  return (
    <div className="tag-list">
      <FavoriteHeart track={null} isFavorite={false} onFavoriteChange={handleFavoriteChange}/>
      {renderedTags.map((tag, index) => (
        <div key={index} className="tag-container">
          {tag}
          {index !== tags.length - 1 && <span className="tag-spacer"> </span>}
        </div>
      ))}
    </div>
  );
};
